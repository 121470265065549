import { Box, Button, Callout, Flex } from "@radix-ui/themes";
import { LoaderFunctionArgs } from "@remix-run/node";
import {
  Form,
  json,
  Link,
  MetaFunction,
  redirect,
  useLoaderData,
  useNavigation,
} from "@remix-run/react";
import { getAuthenticator } from "../services/auth.server";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { commitSession, getSession } from "../services/session.server";
import { InfillaLogo } from "../components/InfillaLogo";
import { pageTitle } from "../utils/page-meta";
import { defaultPathForUser } from "../utils/routes";

export async function loader({ request }: LoaderFunctionArgs) {
  // If the user is already authenticated redirect to /forum directly
  const authenticator = await getAuthenticator();
  const user = await authenticator.isAuthenticated(request, {});

  if (user) {
    return redirect(defaultPathForUser(user));
  } else {
    const session = await getSession(request.headers.get("Cookie"));
    const authError = session.get(
      authenticator.sessionErrorKey as "__flash_auth-error__"
    );
    await commitSession(session); // clear flash message from the session

    return json({ authErrorMessage: authError?.message });
  }
}

export const meta: MetaFunction<typeof loader> = () => {
  return [pageTitle("Sign in")];
};

export default function LoginPage() {
  const data = useLoaderData<typeof loader>();
  const navigation = useNavigation();
  // Show loading states when submitting and redirecting
  const isLoading = navigation.state != "idle";

  return (
    <Flex direction="column" px="4" py="4" gap="2">
      <Box>
        <Link to="/">
          <InfillaLogo size="2" />
        </Link>
      </Box>

      {data.authErrorMessage ? (
        <Box>
          <Callout.Root color="red" mb="2">
            <Callout.Icon>
              <InfoCircledIcon />
            </Callout.Icon>
            <Callout.Text>{data.authErrorMessage}</Callout.Text>
          </Callout.Root>
        </Box>
      ) : (
        <></>
      )}

      <Box>
        <Form action="/auth/microsoft" method="POST">
          <Button type="submit" size="2" loading={isLoading}>
            Sign in with Microsoft
          </Button>
        </Form>
      </Box>
      <Box>
        <Form action="/auth/google" method="POST">
          <Button type="submit" size="2" loading={isLoading}>
            Sign in with Google
          </Button>
        </Form>
      </Box>
    </Flex>
  );
}
